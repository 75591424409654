import React from "react";
import {connect} from "react-redux";
import update from "react-addons-update";
import {postEmail} from "../../../providers/hooks";


class OrderEmail extends React.Component {

    state = {
        form: {
            to: this.props.order.email,
            subject: `HLADIŠA - Porudžbina #${this.props.order.code} je poslata`,
            body: `Porudžbina #${this.props.order.code} je poslata. \n\nHvala na poverenju, \nHLADIŠA`,
        },
        sent: false
    }

    editField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        this.setState(update(this.state, {form: {[field]: {$set: value}} }));
    }

    submit = (e) => {
        e.preventDefault();
        postEmail(this.state.form).then((response) => {
            this.setState({...this.state, sent: true});
            setTimeout(() => {
                this.props.close();
            }, 2000);
        }).catch((error) => {
            alert(error)
        });
    }

    render = () => {
        return <div>
            <form action="/" onSubmit={this.submit}>
                <div className="mb-3">
                    <strong>Email korisnika</strong>
                    <input type="email" className="form-control" value={this.state.form.to}
                       name="to" onChange={this.editField} required />
                </div>
                <div className="mb-3">
                    <strong>Subject</strong>
                    <input type="text" className="form-control" value={this.state.form.subject}
                          name="subject" onChange={this.editField} required />
                </div>
                <div className="mb-3">
                    <strong>Body</strong>
                    <textarea rows={6} className="form-control" value={this.state.form.body}
                           name="body" onChange={this.editField} required />
                </div>
                <button type="submit" className="btn btn-success">Pošaljite Email</button>
                {this.state.sent &&
                <div className="mt-2 alert alert-success">
                    Email uspešno poslat.
                </div>}
            </form>
        </div>
    };
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderEmail);
