import React from "react";
import {connect} from "react-redux";
import {currency} from "../../providers/currencyProvider";


class Price extends React.Component   {

    format = (price) => {
        if(!price) return '';
        if(this.props.extra)    {
            price = parseFloat(price) + parseFloat(this.props.extra);
        }
        price = parseFloat(price).toFixed(2);
        let strPrice = String(price);
        if(price > 999)     {
            const pos = -6;
            strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
        }
        if(price > 999999)     {
            const pos = -10;
            strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
        }
        return strPrice.replace(".", ",");
    };

    render = () => {
        const item = this.props.item;
        const price = this.props.price;
        if(price)       {
            return <span style={{whiteSpace: "nowrap"}}>{this.format(price) + " " + currency(this.props.currency)}</span>
        }


        /*if(!item) {
            return "";
        }
        if(item.discount?.discount)  {
            return  <span style={{whiteSpace: "nowrap"}}>
                {(item.price - ((item.price/100)*item.discount.discount)) + " " + currency(this.props.currency)}
            </span>
        } */

        if(item.min_price && item.max_price && item.min_price !== item.max_price)       {
            return <div> {this.format(item.min_price)} - {this.format(item.max_price) + " " + currency(this.props.currency)} </div>
        }

        if(item.discount_price)  {
            return <span style={{whiteSpace: "nowrap"}}>{this.format(item.discount_price) + " " + currency(this.props.currency)}</span>
        }
        if(item.price)  {
            return <span style={{whiteSpace: "nowrap"}}>{this.format(item.price) + " " + currency(this.props.currency)}</span>
        }
        return "";
    }
}
const mapStateToProps = state => ({
    currency: state.currency
});
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(Price);
