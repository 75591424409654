import React from "react";
import moment from "moment";
import routes from "../../../app/routes";
import {order_status, payment_method} from "../../../providers/orderOptions";
import {connect} from "react-redux";
import Price from "../../theme/Price";
import {storage} from "../../../providers/helpers";
import LiLink from "../../../views/elements/LiLink";
const sqlFrmat = 'Do MMMM YYYY, HH:mm';


class Printable extends React.Component {

    state = {

    }

    print = (elem) =>    {
        let root = document.getElementById("root").innerHTML;
        document.getElementById("root").innerHTML = document.getElementById(elem).innerHTML;

        window.print();
        window.close();
        document.getElementById("root").innerHTML = root;
        return true;
    }

    render = () => {
        let order = this.props.order;
        const status = order_status(order.status);
        return <div>
            <div className="text-right font_2">
                <button onClick={() => this.print("printable")}>
                   <i className="fa fa-print" /> Odštampaj
                </button>
            </div>
            <div id="printable">
                <div className="mb-4 font_4 font-weight-bold">
                    Porudžbina #{order.code}
                </div>

                <div className="bg-white bordered mb-4 p-3">
                    <div className="mb-4 font_4" style={{color: status.color}}>
                        <strong> Status: {status.title} </strong>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Osnovno </div>
                            <div className="mb-1">Šifra</div>
                            <div className="mb-3">
                                <strong>#{order.code}</strong>
                            </div>
                            <div className="mb-1">Cena</div>
                            <div className="mb-3">
                                <div><strong><Price price={order.full_price} /></strong></div>
                            </div>
                            <div className="mb-1">Datum</div>
                            <div className="mb-3">
                                <strong>{moment.utc(order.created_at).local().format(sqlFrmat)}</strong>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Kontakt </div>
                            <div className="mb-1">Ime i prezime</div>
                            <div className="mb-3">
                                <strong>{order.name} {order.lastname}</strong>
                            </div>

                            <div className="mb-1">Telefon</div>
                            <div className="mb-3">
                                <strong>{order.phone}</strong>
                            </div>
                            <div className="mb-1">Email</div>
                            <div className="mb-3">
                                <strong>{order.email}</strong>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Adresa </div>
                            <div className="mb-1">Adresa</div>
                            <div className="mb-3">
                                <strong>{order.address}</strong>
                            </div>
                            <div className="mb-1">Grad</div>
                            <div className="mb-3">
                                <strong>{order.zip} {order.city}</strong>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="font-weight-bold mb-3 border-bottom"> Način plaćanja </div>
                            {payment_method(order.payment_method)}
                        </div>
                    </div>

                    {order.message &&
                    <div className="p-3 mb-3 bcg-2">
                        <div className="font-weight-bold mb-3 border-bottom"> Dodatna napomena </div>
                        <div className="pre_wrap">{order.message}</div>
                    </div>}

                    {order.delivery_address &&
                    <div className="user_bcg p-3">
                        <div className="font-weight-bold mb-3 border-bottom"> Isporuka na drugu adresu </div>

                        <div className="row mb-3">
                            <div className="col-md-3">
                                <div className="mb-1">Ime i prezime</div>
                                <div className="mb-3">
                                    <strong>{order.delivery_name} {order.delivery_lastname}</strong>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-1">Telefon</div>
                                <div className="mb-3">
                                    <strong>{order.delivery_phone}</strong>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-1">Email</div>
                                <div className="mb-3">
                                    <strong>{order.delivery_email}</strong>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="mb-1">Adresa</div>
                                <div className="mb-3">
                                    <strong>{order.delivery_address}</strong>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-1">Grad</div>
                                <div className="mb-3">
                                    <strong>{order.delivery_zip} {order.delivery_city}</strong>
                                </div>
                            </div>
                        </div>
                    </div>}

                </div>

                <table className="table table-bordered">
                    <tbody>
                    {order.products.map((product) => {
                        return <tr key={product.id}>
                            <td width={140}>
                                <img src={storage(product.image_name)} alt={product.size_name} className="img-fluid" />
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <LiLink to={routes.singleProduct.route(product)}>
                                    <strong>{product.size_name}</strong>
                                </LiLink>

                                {product.filters &&
                                <div className="mt-2">
                                    {JSON.parse(product.filters).map((item) => {
                                        return <div key={item.id}>
                                            {item.parent_name}: {item.name}
                                        </div>
                                    })}
                                </div>}
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <strong><Price price={product.price} /></strong>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <div className="mb-1">Količina:</div>
                                <div className="mb-3 font_2">
                                    <strong>{product.qty}</strong>
                                </div>
                            </td>
                            <td style={{verticalAlign: "middle"}}>
                                <div className="mb-1">Ukupno:</div>
                                <div className="mb-3">
                                    <strong><Price price={product.price * product.qty} /></strong>
                                </div>
                            </td>
                        </tr>
                    })}
                    <tr>
                        <td colSpan={4}> </td>
                        <td width={180}>
                            <div className="mb-2 font-weight-bold">Ukupno:</div>
                            <div className="mb-2"><strong><Price price={order.full_price} /></strong></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }

    componentDidMount() {

    }
}
const mapStateToProps = state => ({
    categories: state.categories,
});
export default connect(mapStateToProps)(Printable);