import React from "react";
import {connect} from "react-redux";
import SettingsButton from "../../fragments/SettingsButton";
import {putNewsletter} from "../../../providers/hooks";
import Looper from "../../Looper";


class Newsletter extends React.Component {

    state = {
        email: "",
        saved: false
    }

    submit = (e) => {
        e.preventDefault();
        putNewsletter({email: this.state.email}).then((response) => {
            this.setState({...this.state, saved: true});
        });
    }

    render = () => {
        return <div>
            <SettingsButton path={this.props.path} />
            <div className="footer_newsletter">
                {/*<div className="text-center mb-3 mb-md-0">
                    {this.props.globals.structure.facebook &&
                    <a href={this.props.globals.structure.facebook} className="btn btn-white font-weight-bold" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-facebook" aria-hidden="true" />
                    </a>}
                    {this.props.globals.structure.instagram &&
                    <a href={this.props.globals.structure.instagram} className="btn btn-white font-weight-bold" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-instagram" aria-hidden="true" />
                    </a>}
                    {this.props.globals.structure.twitter &&
                    <a href={this.props.globals.structure.twitter} className="btn btn-white font-weight-bold" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-twitter" aria-hidden="true" />
                    </a>}
                </div>*/}
                {!this.state.saved
                    ? <form action="#" onSubmit={this.submit}>

                        <Looper
                            path="globals.structure.footer newsletter" />


                        <div className="d-flex justify-content-center">
                            <div className="input-group" style={{maxWidth: 600}}>
                                <input type="email"
                                       placeholder="Unesite email..."
                                       className="form-control" value={this.state.email}
                                       onChange={(e) => this.setState({...this.state, email: e.target.value})}
                                       required />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-primary"> Prijavite se </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    : <div className="alert alert-success font_4 pl-5 pr-5 mb-0 text-center"> Sačuvano! </div>}
            </div>
        </div>
    };

}
const mapStateToProps = state => ({
    globals: state.globals
});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
