import React from "react";
import {connect} from "react-redux";
import SettingsButton from "../../fragments/SettingsButton";
import Looper from "../../Looper";
import {storage} from "../../../providers/helpers";


class Instagram extends React.Component {


    render = () => {
        return <div>
            <SettingsButton path={this.props.path} />

            <div className="row">
                <div className="col-md mb-3 mb-md-0">
                    <div className="instagram_bcg pl-4 pt-3 pb-3 d-flex align-items-center"
                         style={{backgroundImage: `url(${storage(this.props.node.bcgImage, true)})`}}>
                        <Looper
                            path={`${this.props.path}.left`} />
                    </div>
                </div>
                <div className="col-md">
                    <div className="row m-0">
                        {[1,2,3,4,5,6,7,8].map((item) => {
                            return  <div className="col-md-3 col-6 p-1" key={item}>
                                <Looper
                                    path={`${this.props.path}.links ${item}`} />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Instagram);
