import React from "react";
import Loader from "../../elements/Loader";
import {connect} from "react-redux";
import {getOrders} from "../../../providers/hooks";
import Price from "../../../builder/theme/Price";
import moment from "moment";
import {order_status} from "../../../providers/orderOptions";
import Pagination from "../../elements/Pagination";
import {Link} from "react-router-dom";
import routes from "../../../app/routes";
const sqlFrmat = 'Do MMMM YYYY, HH:mm';


class Orders extends React.Component {

    state = {
        loading: true,
        orders: {},
        form: {
            page: 1
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let  newState = {...this.state, form: {...this.state.form, page: 1, [name]: value}};
        this.setState(newState);
        this.load(newState.form);
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mt-5 mb-5">
            {this.state.orders.data.length === 0
                ? <div className="font_2">Nema porudžbina</div>
                : <>
                    <table className="table dark_block">
                        <tbody>
                        {this.state.orders.data.map((item) => {
                            const status = order_status(item.status);
                            return <tr key={item.id} style={!item.seen ? {background: "#9bff3a"} : {}}>
                                <td>
                                    <div className="font-weight-bold"><Price price={item.full_price} /></div>
                                    <div>#{item.code}</div>
                                    <div>
                                        {moment.utc(item.created_at).local().format(sqlFrmat)}
                                    </div>
                                </td>
                                <td>
                                    <div className="font-weight-bold"> {item.name} {item.lastname}</div>
                                    <div> {item.email} </div>
                                    <div> {item.phone} </div>
                                </td>
                                <td>
                                    <div className="font-weight-bold"> {item.address} </div>
                                    <div> {item.zip} {item.city} </div>
                                </td>

                                <td width={120}>
                                    <div className="font_2" style={{color: status.color}}>
                                        <strong> {status.title} </strong>
                                    </div>
                                    <Link to={routes.order.route(item.id)} className="btn btn-primary btn-sm mt-1">
                                        Detaljnije
                                    </Link>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    <Pagination
                        change={this.changeField}
                        page={this.state.form.page}
                        lastPage={this.state.orders.last_page} />
                </>}



        </div>
    };

    load = (form) => {
        getOrders(form).then((response) => {
            this.setState({...this.state, orders: response.data, loading: false});
        })

    }

    componentDidMount() {
        this.load(this.state.form);
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
