import React from "react";
import Loader from "../../elements/Loader";
import {
    deleteProductsDiscount,
    getProducts,
    getProductsDiscounts,
    putProductsDiscount
} from "../../../providers/hooks";
import ProductsItem from "./ProductsItem";
import routes from "../../../app/routes";
import {Link} from "react-router-dom";
import Pagination from "../../elements/Pagination";
import update from "react-addons-update";
import {connect} from "react-redux";
import Nestable from "react-nestable";
import Select from "../../elements/edit/Select";
import {findCategoryBySlug, flatten, getChildrenIds} from "../../../providers/helpers";
import Modal from "../../framework/Modal";


class Products extends React.Component {

    state = {
        loading: true,
        products: {},
        category: "",
        discountModal: "",
        discounts: [],
        form: {
            all: true,
            page: 1,
            selected: [],
            search: "",
            categories: "",
            discount_name: "",
            discount: "",
            discount_message: ""
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newState;
        if(name === "page") {
            newState = update(this.state, {form: {[name]: {$set: value}}});
        } else  {
            newState = update(this.state, {form: {[name]: {$set: value}, page: {$set: 1}}});
        }
        this.setState(newState);
        if(this.props.history)  {
            this.props.history.push(this.route(newState.form));
        }
        this.load(newState.form);
    }

    changeDiscount = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newState;
        newState = update(this.state, {form: {[name]: {$set: value}}});
        this.setState(newState);
    }

    route = (form = false) => {
        if(!form) form = this.state.form;
        let params = [];
        for(let key in form) {
            let value = form[key];
            if(!value) continue;
            if(typeof value === "object")    {
                value = JSON.stringify(value);
            }
            if(Array.isArray(value))    {
                value = JSON.stringify(value);
            }
            params.push(`${key}=${value}`);
        }
        if(params.length === 0) {
            return "";
        }
        return "?" + params.join("&");
    }

    select = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        let newState;
        if(checked)     {
            newState = update(this.state, {form: {selected: {$push: [parseInt(value)]}}});
            this.setState(newState);
        } else  {
            const selected = this.state.form.selected.filter(item => item !== parseInt(value));
            newState = update(this.state, {form: {selected: {$set: selected}}});
            this.setState(newState);
        }
        if(this.props.drawer.callback) {
            this.props.drawer.callback(newState.form.selected);
            this.load(newState.form);
        }

    }

    renderItem = (props) => {
        const item = props.item;
        const checked = this.state.form.selected.includes(item.id);
        return <div key={item.id} className="d-flex align-items-center">
            {this.props.inDrawer &&
            <div style={{width: 70}}>
                {checked && <div className="mb-2">{props.handler}</div>}
                <label>
                    <input
                        type="checkbox"
                        onChange={this.select}
                        value={item.id}
                        checked={checked} />
                </label>
            </div>}
            <ProductsItem
                drawer={this.props.drawer}
                key={item.id}
                item={item}
                reload={this.load} />
        </div>
    }

    changedOrder = (items) => {
        const products = items.filter(item => this.state.form.selected.includes(item.id));
        const ids = products.map(item => item.id);
        if(this.props.drawer.callback) {
            this.props.drawer.callback(ids);
        }
    }

    renderCategoryItem = (item) => {
        return <option key={item.id} value={item.slug}>
            {" - ".repeat(item.lvl)} {item.name}
        </option>
    }

    changeCategory = (e) => {
        const newState = update(this.state, {category: {$set: e.target.value}, form: {[e.target.name]:
                    {$set: e.target.value ? getChildrenIds(findCategoryBySlug(e.target.value, this.props.categories)) : "" }}});
        this.setState(newState);
        this.load(newState.form);

    }

    discount = (e) => {
        e.preventDefault();
        putProductsDiscount(this.state.form).then((response) => {
            console.log(response.data)
            const newState = update(this.state, {form: {discount_name: {$set: ""}, discount: {$set: ""},
                    discount_message: {$set: ""}}, discountModal: {$set: false}});
            this.setState(newState);
            this.loadDiscounts();
            this.load(newState.form);
        });
    }
    deleteDiscount = (id) => {
        var r = window.confirm("Želite da obrišete popust?");
        if (r !== true) {
            return false;
        }
        deleteProductsDiscount({id: id}).then((response) => {
            this.loadDiscounts();
            this.load(this.state.form);
        })
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mb-5">
            <Modal
                width={560}
                open={this.state.discountModal}
                close={() => this.setState({...this.state, discountModal: false})}>

               <div className="font-italic mb-2">
                  * Sniženje se dodaje samo na proizvode ispod
               </div>
                <form action="/" onSubmit={this.discount}>
                    <div className="mb-3">
                        <div className="mb-1 font-weight-bold">Naziv</div>
                        <input type="text" className="form-control" required
                               value={this.state.form.discount_name}
                               onChange={this.changeDiscount} name="discount_name" />
                    </div>
                    <div className="mb-1">Sniženje (obavezno)</div>
                    <div className="input-group mb-2" style={{width: 200}}>
                        <input type="number" className="form-control" required value={this.state.form.discount}
                               onChange={this.changeDiscount}  name="discount" />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                    </div>
                    <div className="mb-1">Kratka poruka ispod cene</div>
                    <input type="text" className="form-control mb-2" value={this.state.form.discount_message}
                          onChange={this.changeDiscount} name="discount_message" />
                  <button className="btn btn-success" type="submit">Dodajte</button>
                </form>
            </Modal>


            {this.state.discounts.length > 0 && !this.props.drawer.selected &&
            <table className="table table-sm">
                <thead>
                    <tr>
                        <td colSpan={3} className="text-center font-weight-bold">POPUSTI</td>
                    </tr>
                </thead>
                <tbody>
                {this.state.discounts.map((item) => {
                    return <tr key={item.id}>
                        <td><strong>{item.discount_name}</strong></td>
                        <td>{item.discount}%</td>
                        <td>{item.discount_message}</td>
                        <td>proizvodi: {item.check_discount ? item.count : 0}</td>
                        <td width={100}>
                            <button
                                onClick={() => this.deleteDiscount(item.id)}
                                className="btn btn-danger btn-sm">Obrišite</button>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>}

            {/*!this.props.drawer.selected &&
            <button onClick={() => this.setState({...this.state, discountModal: true})}
                className="btn btn-info mb-2">DODAJTE POPUST NA PROIZVODE</button>*/}




            <div className="mb-3 d-flex form-inline">
                {!this.props.drawer.selected &&
                <Link to={routes.product.route()} className="btn btn-primary mr-3">
                    <i className="fa fa-plus" /> Dodajte proizvod
                </Link>}

                <input
                    value={this.state.form.search}
                    name="search"
                    className="form-control mr-3"
                    onChange={this.changeField}
                    type="text" placeholder="Pretražite po imenu..." />

                <Select
                    name="categories"
                    value={this.state.category}
                    onChange={this.changeCategory}
                    options={flatten(this.props.categories)}
                    render={this.renderCategoryItem} />
            </div>


            {this.state.products.data.length > 0
                ? <div>
                    <div className="font-weight-bold mb-2">
                        Ukupno: {this.state.products.total}
                    </div>
                    <Nestable
                        maxDepth={1}
                        handler={<span className="nestable_icon"><i className="fa fa-arrows" /></span>}
                        onChange={this.changedOrder}
                        items={this.state.products.data}
                        renderItem={this.renderItem} />

                    <Pagination
                        change={this.changeField}
                        page={this.state.form.page}
                        lastPage={this.state.products.last_page} />
                </div>
                : <strong> Nema proizvoda </strong>}
        </div>
    };

    load = (form = false) => {
        if(!form) form = this.state.form;
        if(this.props.history)      {
            const query = this.props.history.location.search; // could be '?foo=bar'
            const params =  new URLSearchParams(decodeURIComponent(query));
            let pageUrl = params.get('page') ? params.get('page') : 1;
            form = {...form, page: pageUrl};
        }

        getProducts(form).then((response) => {
            this.setState({...this.state, form: form, loading: false, products: response.data});
        });
    }

    loadDiscounts = ()  => {
        getProductsDiscounts().then((response) => {
            this.setState({...this.state, discounts: response.data});
            console.log(response.data)
        })
    }

    componentDidMount() {
        if(this.props.drawer.selected)      {
            const newState = update(this.state, {form: {selected: {$set: this.props.drawer.selected}}});
            this.setState(newState);
            this.load(newState.form);
            return false;
        }
        this.loadDiscounts();
        this.load(this.state.form);
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.products,
    categories: state.categories
});
export default connect(mapStateToProps)(Products);
