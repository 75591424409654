import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import dotProp from "dot-prop-immutable";
import {Path} from "../Path";
import {insertGlobalsNode} from "../../store/actions/globalsActions";
import {openDrawer} from "../../store/actions/drawersActions";
import SettingsButton from "../fragments/SettingsButton";
import routes from "../../app/routes";
import LiLink from "../../views/elements/LiLink";


class Menu extends React.Component {

    state = {
        pathObj: Path(this.props.path),
        categoriesDropDown: false
    }

    toggleCategoriesMenu = (e) => {
        e.preventDefault();
        if(!this.state.categoriesDropDown)   {
            setTimeout(() => {
                document.addEventListener('click', this.outsideClickListener);
            }, 10);
        } else  {
            this.removeClickListener();
        }
        this.setState({...this.state, categoriesDropDown: !this.state.categoriesDropDown});
    };
    removeClickListener = () => {
        document.removeEventListener('click', this.outsideClickListener)
    }
    outsideClickListener = () => {
        this.removeClickListener();
        this.setState({...this.state, categoriesDropDown: false});
    };

    render = () => {
        const node = dotProp.get(this.props, this.props.path, {});
        const classname = node.classes ? Object.values(node.classes).join(" ") : "";

        return <div style={{background: "#eeedee"}}>

            <div className="container">
                <SettingsButton path={this.props.path} />

                <div className={`position-relative ${classname}`}>
                    <div className="text-nowrap header_menu d-flex align-items-center">
                        <button
                            //onClick={() => this.props.openDrawer('categories')}
                            onClick={this.toggleCategoriesMenu}
                            className="btn btn-primary font-weight-bold header_menu_cat mr-3 d-none d-md-inline-block">
                            KATEGORIJE PROIZVODA <i className="ml-3 fa fa-bars" />
                        </button>
                        {this.props.menu.map((item) => {
                            if(!item.header) return "";
                            const selected = item.slug === this.props.page?.slug;
                            let link = false;
                            let url = `/${item.slug}`;
                            if(item.link)       {
                                if(item.link.startsWith("http://") || item.link.startsWith("https://"))    {
                                    const port = window.location.port ? ":" + window.location.port : "";
                                    const host = `${window.location.protocol}//${window.location.hostname}${port}`
                                    if(item.link.startsWith(host))  {
                                        url += "/" + item.link.replace(host, "");
                                    } else  {
                                        link = item.link;
                                    }
                                } else  {
                                    url += "/" + item.link;
                                }
                            }
                            return <div key={item.slug} className={selected ? "header_menu_active builder_title builder_title_sm" : "header_menu_link"}>
                            <span>
                                 {link
                                     ? <a href={link} style={node.style || {}}>
                                         {item.name}
                                     </a>
                                     : <Link to={url} style={node.style || {}}>
                                         {item.name}
                                     </Link>}
                            </span>
                            </div>
                        })}
                    </div>

                    <div className={`header_menu_categories ${this.state.categoriesDropDown ? "copen" : ""}`}>
                        {this.props.categories.map((item) => {
                            return <div key={item.id}>
                                <LiLink className="d-flex justify-content-between align-items-center"
                                        to={routes.singleCategory.route(item)}>
                                    {item.name}  {item.children.length > 0 && <span>&#x203A;</span>}
                                </LiLink>
                                <div className="header_menu_subcategories">
                                    {item.children.map((child) => {
                                        return <div key={child.id}>
                                            <LiLink to={routes.singleCategory.route(child)}>
                                                {child.name}
                                        </LiLink>
                                        </div>})}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>


        </div>
    };

    componentDidMount() {
        const node = dotProp.get(this.props, this.props.path, false);
        if(!node && this.props.default)        {
            this.props.insertGlobalsNode({path: this.state.pathObj.path, node: this.props.default});
        }
    }
}
const mapStateToProps = state => ({
    menu: state.menu,
    page: state.page,
    globals: state.globals,
    admin: state.admin,
    preview: state.preview,
    categories: state.categories
});
const mapDispatchToProps = {
    insertGlobalsNode: insertGlobalsNode,
    openDrawer: openDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
