import React from "react";
import MarginSettings from "../../fragments/settings/MarginSettings";
import PaddingSettings from "../../fragments/settings/PaddingSettings";
import DeleteNodeSettings from "../../fragments/settings/DeleteNodeSettings";
import BackgroundSettings from "../../fragments/settings/BackgroundSettings";
import CarouselSettings from "../../fragments/settings/CarouselSettings";

class CarouselSet extends React.Component {

    render = () => {
        return <>
            <CarouselSettings edit={this.props.edit} save={this.props.save} node={this.props.node} />
            <BackgroundSettings edit={this.props.edit} save={this.props.save} node={this.props.node} />
            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <PaddingSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
            <DeleteNodeSettings save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default CarouselSet;
