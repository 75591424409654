import axios from "axios";

export function server(path) {
    let gateway = `https://hladisa.rs/${path}`;
    return gateway;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        gateway = `http://127.0.0.1:5000/${path}`;
    }
    return gateway;
}


export async function getMenu() {
    return await axios.get(server("menu"));
}
export async function getPage(form) {
    return await axios.get(server("page"), {params: form});
}
export async function putPage(form) {
    return await axios.put(server("page"), form);
}
export async function postPagesOrder(form) {
    return await axios.post(server("pages"), form);
}

export async function getGlobals() {
    return await axios.get(server("globals"));
}

export async function getProducts(form) {
    return await axios.get(server("products"), {params: form});
}
export async function getProduct(form) {
    return await axios.get(server("product"), {params: form});
}
export async function putProduct(form) {
    return await axios.put(server("product"), form);
}
export async function putProductImages(form) {
    return await axios.put(server("product/images"), form);
}
export async function postProductImagesOrder(form) {
    return await axios.post(server("product/images/order"), form);
}
export async function putProductCategories(form) {
    return await axios.put(server("product/categories"), form);
}
export async function putProductFilters(form) {
    return await axios.put(server("product/filters"), form);
}
export async function putProductRecommendations(form) {
    return await axios.put(server("product/recommendations"), form);
}
export async function patchProductPosition(form) {
    return await axios.patch(server("product/position"), form);
}
export async function getProductsDesign(form) {
    return await axios.get(server("products/design"), {params: form});
}
export async function getProductsDiscounts(form) {
    return await axios.get(server("products/discounts"), {params: form});
}
export async function putProductsDiscount(form) {
    return await axios.put(server("products/discount"), form);
}
export async function deleteProductsDiscount(form) {
    return await axios.delete(server("products/discount"), {data: form});
}

export async function postOrderForm(form) {
    return await axios.post(server("order-form"), form);
}
export async function getOrder(form) {
    return await axios.get(server("order"), {params: form});
}
export async function getOrders(form) {
    return await axios.get(server("orders"), {params: form});
}
export async function getOrdersCount(form) {
    return await axios.get(server("orders/count"), {params: form});
}

export async function getCategories(form) {
    return await axios.get(server("categories"), {params: form});
}
export async function putCategory(form) {
    return await axios.put(server("category"), form);
}
export async function postCategoriesOrder(form) {
    return await axios.post(server("categories"), form);
}

export async function getFilters(form) {
    return await axios.get(server("filters"), {params: form});
}
export async function putFilter(form) {
    return await axios.put(server("filter"), form);
}
export async function postFiltersOrder(form) {
    return await axios.post(server("filters"), form);
}
export async function getFiltersByCategory(form) {
    return await axios.get(server("filters/category"), {params: form});
}

export async function getFiles(form) {
    return await axios.get(server("files"), {params: form});
}
export async function deleteFiles(form) {
    return await axios.delete(server("files"), {data: form});
}


export async function patchPath(form) {
    return await axios.patch(server("path"), form);
}
export async function deletePath(form) {
    return await axios.delete(server("path"), {data: form});
}

export async function putNewsletter(form) {
    return await axios.put(server("newsletter"), form);
}

/// USERS
export async function postUserLogin(form) {
    return await axios.post(server("user/login"), form);
}
export async function postUserRegister(form) {
    return await axios.post(server("user/register"), form);
}
export async function postUserPasswordRecovery(form) {
    return await axios.post(server("user/password/recovery"), form);
}
export async function getUsers(form) {
    return await axios.get(server("users"), {params: form});
}
export async function getUser(form) {
    return await axios.get(server("user"), {params: form});
}
export async function patchUser(form) {
    return await axios.patch(server("user"), form);
}
export async function deleteUser(form) {
    return await axios.delete(server("user"), form);
}
export async function getUserOrders(form) {
    return await axios.get(server("user/orders"), {params: form});
}

export async function postAdminLogin(form) {
    return await axios.post(server("admin/login"), form);
}
export async function getAdmins(form) {
    return await axios.get(server("admins"), {params: form});
}
export async function putAdmin(form) {
    return await axios.put(server("admin"), form);
}

export async function getNewsletters(form) {
    return await axios.get(server("newsletters"), {params: form});
}

export async function getBrands(form) {
    return await axios.get(server("brands"), {params: form});
}

export async function getOptions(form) {
    return await axios.get(server("options"), {params: form});
}
export async function putOption(form) {
    return await axios.put(server("options"), form);
}
export async function putOptionElement(form) {
    return await axios.put(server("options/element"), form);
}
export async function putOptionSelect(form) {
    return await axios.put(server("options/select"), form);
}
export async function putCategoryOption(form) {
    return await axios.put(server("options/category"), form);
}
export async function putOptionProducts(form) {
    return await axios.put(server("options/products"), form);
}

export async function postEmail(form) {
    return await axios.post(server("email"), form);
}
export async function postContactForm(form) {
    return await axios.post(server("contact-form"), form);
}

export async function putProductSizes(form) {
    return await axios.put(server("product/sizes"), form);
}
export async function postProductSizesOrder(form) {
    return await axios.post(server("product/sizes/order"), form);
}
export async function getProductsSizes(form) {
    return await axios.get(server("products/sizes"), {params: form});
}