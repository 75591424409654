import React from "react";
import {connect} from "react-redux";
import Looper from "../../Looper";
import SettingsButton from "../../fragments/SettingsButton";
import ReCAPTCHA from "react-google-recaptcha";
import {postContactForm} from "../../../providers/hooks";
import update from "react-addons-update";


class Contact extends React.Component {

    form = {
        email: "",
        ime: "",
        poruka: "",
        recaptcha: ""

    }
    state = {
        form: this.form,
        error: {},
        success: false,
        loading: false
    };

    changeField = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        this.setState(update(this.state, {form: {[name]: {$set: value}} }));
    };

    submit = (e) => {
        e.preventDefault();
        this.setState({...this.state, loading: true, error: {}});
        postContactForm(this.state.form).then((response) => {
            this.setState({...this.state, loading: false, success: true, error: {}});
        }).catch((error) => {
            this.setState({...this.state, loading: false, error: error, success: false});
        });
    };

    onChange = (key) => {
        this.setState(update(this.state, {form: {recaptcha: {$set: key}} }));
    }

    render = () => {
        let form = this.state.form;
        return <div className="row">
            <SettingsButton path={this.props.path} />

                <div className="col-md-8">
                    <Looper path={`${this.props.path}.title`}  />

                    <form className="mb-md-0 mb-3" action="#" method="post" onSubmit={this.submit}>
                        <div className="mb-3">
                            <input onChange={this.changeField} value={form.ime}
                                   type="text" name="ime" className="form-control form-control-lg font_1" placeholder="Ime" required />
                        </div>
                        <div className="mb-3">
                            <input type="text" onChange={this.changeField} value={form.email}
                                   name="email" className="form-control form-control-lg font_1" placeholder="Email" required />
                        </div>
                        <div className="mb-3">
                            <textarea onChange={this.changeField} value={form.poruka}
                                      name="poruka" className="form-control form-control-lg font_1" placeholder="Poruka..." />
                        </div>

                        <div className="mb-3">
                            <ReCAPTCHA
                                sitekey="6Ld9ir8cAAAAABqI3OH7OT7VmjCdtGVOC-7v-tcU"
                                onChange={this.onChange} />
                        </div>

                        <div className="">
                            <button disabled={this.state.loading} className="btn btn-primary" type="submit">
                                <i className="fa fa-send" /> Pošaljite </button>
                        </div>
                    </form>

                    {this.state.error?.message &&
                    <div className="alert alert-danger mt-3">
                        {this.state.error.message}
                    </div>}

                    {this.state.success &&
                    <div className="alert alert-success mt-3">
                        Uspešno poslato.
                    </div>}
                </div>
                <div className="col-md-4">
                    <Looper path={`${this.props.path}.address`}  />
                </div>
            </div>
    };

    componentDidMount() {
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
