import React from "react";
import {connect} from "react-redux";
import Looper from "../Looper";


class Socials extends React.Component {

    render = () => {
        let classname = " text-center d-flex justify-content-center ";
        if(this.props.node.classes)     {
            classname += Object.values(this.props.node.classes).join(" ");
        }
        if(!this.props.node.classes?.buttonColor)       {
            classname += " ";
        }
        return <div className={classname}>
            {this.props.globals.structure.facebook &&
            <Looper
                path="globals.structure.socials facebook" />}
            {this.props.globals.structure.instagram &&
            <Looper
                path="globals.structure.socials instagram" />}
            {this.props.globals.structure.youtube &&
            <Looper
                path="globals.structure.socials youtube" />}
            {this.props.globals.structure.twitter &&
            <Looper
                path="globals.structure.socials twitter" />}
        </div>
    };

}
const mapStateToProps = state => ({
    globals: state.globals
});
export default connect(mapStateToProps)(Socials);
