import Template1 from "./templates/Template1";
import Template2 from "./templates/Template2";
import Text from "./elements/Text";
import Image from "./elements/Image";
import Grid1 from "./grids/Grid1";
import Grid2 from "./grids/Grid2";
import Title from "./elements/Title";
import Slider from "./grids/slider/Slider";
import Button from "./elements/Button";
import Grid3 from "./grids/Grid3";
import ProductsCarousel from "./grids/ProductsCarousel";
import Contact from "./grids/contact/Contact";
import Shop from "./grids/shop/Shop";
import Basket from "./grids/basket/Basket";
import Product from "./grids/product/Product";
import Map from "./grids/map/Map";
import TextSet from "./elements/TextSet";
import ImageSet from "./elements/ImageSet";
import ButtonSet from "./elements/ButtonSet";
import GridSet from "./grids/GridSet";
import ProductsCarouselSet from "./grids/ProductsCarouselSet";
import BasketSet from "./grids/basket/BasketSet";
import ContactSet from "./grids/contact/ContactSet";
import MapSet from "./grids/map/MapSet";
import ProductSet from "./grids/product/ProductSet";
import ShopSet from "./grids/shop/ShopSet";
import Newsletter from "./grids/newsletter/Newsletter";
import NewsletterSet from "./grids/newsletter/NewsletterSet";
import UserNewsletter from "./grids/auth/newsletter/Newsletter";
import UserNewsletterSet from "./grids/auth/newsletter/NewsletterSet";
import Login from "./grids/auth/login/Login";
import Register from "./grids/auth/register/Register";
import RegisterSet from "./grids/auth/register/RegisterSet";
import Recovery from "./grids/auth/recovery/Recovery";
import RecoverySet from "./grids/auth/recovery/RecoverySet";
import Info from "./grids/auth/info/Info";
import InfoSet from "./grids/auth/info/InfoSet";
import Orders from "./grids/auth/orders/Orders";
import OrdersSet from "./grids/auth/orders/OrdersSet";
import ThankYou from "./grids/basket/ThankYou";
import OrderStatus from "./grids/basket/OrderStatus";
import Brands from "./grids/brands/Brands";
import BrandsSet from "./grids/brands/BrandsSet";
import Socials from "./elements/Socials";
import SocialsSet from "./elements/SocialsSet";
import Breadcrumbs from "./grids/breadcrumbs/Breadcrumbs";
import BreadcrumbsSet from "./grids/breadcrumbs/BreadcrumbsSet";
import Instagram from "./grids/instagram/Instagram";
import InstagramSet from "./grids/instagram/InstagramSet";
import Gallery from "./grids/gallery/Gallery";
import GallerySet from "./grids/gallery/GallerySet";
import Popup from "./elements/Popup";
import PopupSet from "./elements/PopupSet";
import Carousel from "./grids/carousel/Carousel";
import CarouselSet from "./grids/carousel/CarouselSet";

export let templates =  {
    "template 1": Template1,
    "template 2": Template2,
}
export let elements =  {
    text: {
        element: Text,
        name: "Text",
        image: "",
        description: "Lorem ipsum dolr",
        default: {element: "text", text: "Lorem Ipsum"},
        settings: TextSet
    },
    image: {
        element: Image,
        name: "Slika",
        image: "",
        description: "Lorem ipsum dolr",
        default: {element: "image"},
        settings: ImageSet
    },
    popup: {
        element: Popup,
        name: "POPUP Slika",
        image: "",
        description: "Lorem ipsum dolr",
        default: {element: "popup"},
        settings: PopupSet
    },
    title: {
        element: Title,
        name: "Naslov",
        image: "",
        description: "Sa donjom crtom",
        default: {element: "title", text: "Lorem Ipsum",
            classes: {fontSize: "font_1", fontWeight: "font-weight-bold"}},
        settings: TextSet
    },
    button: {
        element: Button,
        name: "Button",
        image: "",
        description: "Lorem ipsum dolr",
        default: {element: "button", text: "Click me!"},
        settings: ButtonSet
    },
    socials: {
        element: Socials,
        name: "Socijalne mreže",
        image: "",
        description: "Lorem ipsum dolr",
        default: {element: "socials"},
        settings: SocialsSet
    }
}

export let grids =  {
    "grid 1": {
        element: Grid1,
        name: "Kolone (1-6)",
        image: "",
        description: "Elementi vertikalno",
        folder: "Kolone",
        default: {grid: "grid 1", blockCount: 2, slides: [[], []]},
        settings: GridSet
    },
    "grid 3": {
        element: Grid3,
        name: "Kolone (1-6)",
        image: "",
        folder: "Kolone",
        description: "Elementi su vodoravno",
        default: {grid: "grid 3", blockCount: 2, slides: [[], [], [], []]},
        settings: GridSet
    },
    "grid 2": {
        element: Grid2,
        name: "Kolone podeljene na 2 (1-6)",
        image: "",
        folder: "Kolone",
        description: "Svaka kolona na 2 dela",
        default: {grid: "grid 2", blockCount: 2, slides: [[], [], [], []]},
        settings: GridSet
    },
    "slider": {
        element: Slider,
        name: "Veliki slajder",
        image: "",
        folder: "Slajderi",
        description: "Preko celog",
        default: {grid: "slider", blockCount: 3, slides: [
            [{element: "image", classes: {textAlign: "text-center"}}],
            [{element: "image", classes: {textAlign: "text-center"}}],
            [{element: "image", classes: {textAlign: "text-center"}}],
            ]},
        settings: GridSet
    },
    "carousel": {
        element: Carousel,
        name: "Karusel",
        image: "",
        folder: "Slajderi",
        description: "karousel",
        default: {grid: "carousel", blockCount: 3, slides: [
                [{element: "image", classes: {textAlign: "text-center"}}],
                [{element: "image", classes: {textAlign: "text-center"}}],
                [{element: "image", classes: {textAlign: "text-center"}}],
            ]},
        settings: CarouselSet
    },
    "gallery": {
        element: Gallery,
        name: "Galerija",
        image: "",
        description: "Elementi horizontalno",
        folder: "Kolone",
        default: {grid: "gallery"},
        settings: GallerySet
    },
    "products": {
        element: ProductsCarousel,
        name: "Slajder proizvodi",
        image: "",
        folder: "Slajderi",
        description: "Slajder sa proizvodima",
        default: {grid: "products", products: []},
        settings: ProductsCarouselSet
    },

    "contact": {
        element: Contact,
        name: "Kontakt forma",
        image: "",
        folder: "Delovi",
        description: "Kontakt formular",
        default: {grid: "contact"},
        settings: ContactSet
    },
    "map": {
        element: Map,
        name: "Google mapa",
        image: "",
        folder: "Delovi",
        description: "Google mapa",
        default: {grid: "map"},
        settings: MapSet
    },
    "breadcrumbs": {
        element: Breadcrumbs,
        name: "Breadcrumbs",
        image: "",
        folder: "Delovi",
        description: "breadcrumbs",
        default: {grid: "breadcrumbs", classes: { marginBottom: "mb_30", marginTop: "mt_20"},
            links: [{element: "text", text: "Naslovna", icon: "fa fa-angle-right", iconRight: "on", route: "naslovna",
                classes: {"marginRight": "mr_15"}}, {element: "text", text: "Ime strane"}]},
        settings: BreadcrumbsSet
    },

    "instagram": {
        element: Instagram,
        name: "Instagram",
        image: "",
        folder: "Delovi",
        description: "Instagram",
        default: {grid: "instagram", classes: { marginBottom: "mb_30", marginTop: "mt_20"}},
        settings: InstagramSet
    },

    "newsletter": {
        element: Newsletter,
        name: "Newsletter",
        image: "",
        folder: "Delovi",
        description: "Newsletter form",
        default: {grid: "newsletter"},
        settings: NewsletterSet
    },
    "shop": {
        element: Shop,
        name: "Shop strana",
        image: "",
        folder: "Skriveni",
        description: "Shop strana",
        default: {grid: "shop"},
        settings: ShopSet
    },
    "product": {
        element: Product,
        name: "Product strana",
        image: "",
        folder: "Skriveni",
        description: "Jedan product strana",
        default: {grid: "product"},
        settings: ProductSet
    },
    "brands": {
        element: Brands,
        name: "Brands strana",
        image: "",
        folder: "Skriveni",
        description: "",
        default: {grid: "brands"},
        settings: BrandsSet
    },
    "basket": {
        element: Basket,
        name: "basket strana",
        image: "",
        folder: "Skriveni",
        description: "basket strana",
        default: {grid: "basket",
            title: [{element: 'title', text: 'Vaša korpa', classes: {marginBottom: "mb_20", fontSize: 'font_4'}}],
            title1: [{element: 'title', text: 'Informacije', classes: {marginBottom: "mb_20", fontSize: 'font_4'}}],
            title2: [{element: 'title', text: 'Dostava', classes: {marginBottom: "mb_20", fontSize: 'font_4'}},
                {element: 'text', text: 'Dostava se vrši preko "City Express" kurirske službe i iznosi 250 din.', classes: {marginBottom: "mb_20"}},],
            title3: [{element: 'title', text: 'Način plaćanja', classes: {marginBottom: "mb_20", fontSize: 'font_4'}}],
            title4: [{element: 'title', text: 'UKUPNO', classes: {marginBottom: "mb_20", fontSize: 'font_4'}}],},
        settings: BasketSet
    },
    "thankYou": {
        element: ThankYou,
        name: "Thank you strana",
        image: "",
        folder: "Skriveni",
        description: "",
        default: {grid: "thankYou"},
        settings: BasketSet
    },
    "orderStatus": {
        element: OrderStatus,
        name: "orderStatus strana",
        image: "",
        folder: "Skriveni",
        description: "",
        default: {grid: "orderStatus"},
        settings: BasketSet
    },
    "login": {
        element: Login,
        name: "login strana",
        image: "",
        folder: "Skriveni",
        description: "login strana",
        default: {grid: "login"},
        settings: BasketSet
    },
    "register": {
        element: Register,
        name: "register strana",
        image: "",
        folder: "Skriveni",
        description: "register strana",
        default: {grid: "register"},
        settings: RegisterSet
    },
    "recovery": {
        element: Recovery,
        name: "password recovery strana",
        image: "",
        folder: "Skriveni",
        description: "recovery strana",
        default: {grid: "recovery"},
        settings: RecoverySet
    },
    "info": {
        element: Info,
        name: "user info",
        image: "",
        folder: "Skriveni",
        description: "user info strana",
        default: {grid: "info", title1: [
                {element: 'title', text: 'Informacije', classes: {marginBottom: "mb_20", fontSize: 'font_4'}},
                {element: 'text', text: 'Ukoliko je potrebno, vaše podatke možete u svakom momentu ažurirati.', classes: {marginBottom: "mb_20"}},
            ],
            title2: [
                {element: 'title', text: 'Adresa', classes: {marginBottom: "mb_20", fontSize: 'font_4'}}
                ]},
        settings: InfoSet
    },
    "orders": {
        element: Orders,
        name: "user orders",
        image: "",
        folder: "Skriveni",
        description: "user orders strana",
        default: {grid: "orders", title: [
                {element: 'title', text: 'Vaše porudžbine', classes: {marginBottom: "mb_20", fontSize: 'font_4'}},
            ]},
        settings: OrdersSet
    },
    "userNewsletter": {
        element: UserNewsletter,
        name: "user newsletter",
        image: "",
        folder: "Skriveni",
        description: "user newsletter strana",
        default: {grid: "userNewsletter", title: [
                {element: 'title', text: 'Newslettter', classes: {marginBottom: "mb_20", fontSize: 'font_4'}},
            ]},
        settings: UserNewsletterSet
    }
}

export let classes =  {
    fontSize: [
        ["Font 1", "font_08"],
        ["Font 2", "font_09"],
        ["Font 3", "font_1"],
        ["Font 4", "font_2"],
        ["Font 5", "font_3"],
        ["Font 6", "font_6"],
        ["Font 7", "font_10"],
        ["Font 8", "font_15"],
        ["Font 9", "font_20"],
        ["Font 10", "font_30"],
    ],
    textAlign: [
        ["Left", "text-left"],
        ["Center", "text-center"],
        ["Right", "text-right"],
    ],
    fontWeight: [
        ["Bold", "font-weight-bold"]
    ],
    fontColor: [
        ["Primarna", "font_primary"],
        ["Sekundarna", "font_secondary"],
        ["Svetla", "font_light"],
    ],
    bcgColor: [
        ["Primarna", "bcg_primary"],
        ["Sekundarna", "bcg_secondary"],
    ],
    buttonColor: [
        ["Primarna", "btn-primary"],
        ["Sekundarna", "btn-secondary"],
        ["Primarna outline", "btn-outline-primary"],
        ["Sekundarna outline", "btn-outline-secondary"],
        ["Bela", "btn-white"],
    ],
    backgroundRepeat: [
        ["No repeat", "no_repeat"],
        ["Repeat x", "repeat_x"],
        ["Repeat y", "repeat_y"],
    ],
    marginBottom: [
        ["Bottom 5", "mb_5"],
        ["Bottom 10", "mb_10"],
        ["Bottom 15", "mb_15"],
        ["Bottom 20", "mb_20"],
        ["Bottom 30", "mb_30"],
        ["Bottom 50", "mb_50"],
        ["Bottom 70", "mb_70"],
        ["Bottom 100", "mb_100"],
        ["Bottom 150", "mb_150"],
        ["Bottom 200", "mb_200"],
    ],
    marginTop: [
        ["Top 5", "mt_5"],
        ["Top 10", "mt_10"],
        ["Top 15", "mt_15"],
        ["Top 20", "mt_20"],
        ["Top 30", "mt_30"],
        ["Top 50", "mt_50"],
        ["Top 70", "mt_70"],
        ["Top 100", "mt_100"],
        ["Top 150", "mt_150"],
        ["Top 200", "mt_200"],
    ],
    marginLeft: [
        ["Left 5", "ml_5"],
        ["Left 10", "ml_10"],
        ["Left 15", "ml_15"],
        ["Left 20", "ml_20"],
        ["Left 30", "ml_30"],
        ["Left 50", "ml_50"],
        ["Left 70", "ml_70"],
        ["Left 100", "ml_100"],
        ["Left 150", "ml_150"],
        ["Left 200", "ml_200"],
    ],
    marginRight: [
        ["Right 5", "mr_5"],
        ["Right 10", "mr_10"],
        ["Right 15", "mr_15"],
        ["Right 20", "mr_20"],
        ["Right 30", "mr_30"],
        ["Right 50", "mr_50"],
        ["Right 70", "mr_70"],
        ["Right 100", "mr_100"],
        ["Right 150", "mr_150"],
        ["Right 200", "mr_200"],
    ],
    paddingBottom: [
        ["Bottom 5", "pb_5"],
        ["Bottom 10", "pb_10"],
        ["Bottom 15", "pb_15"],
        ["Bottom 20", "pb_20"],
        ["Bottom 30", "pb_30"],
        ["Bottom 50", "pb_50"],
        ["Bottom 70", "pb_70"],
        ["Bottom 100", "pb_100"],
        ["Bottom 150", "pb_150"],
        ["Bottom 200", "pb_200"],
    ],
    paddingTop: [
        ["Top 5", "pt_5"],
        ["Top 10", "pt_10"],
        ["Top 15", "pt_15"],
        ["Top 20", "pt_20"],
        ["Top 30", "pt_30"],
        ["Top 50", "pt_50"],
        ["Top 70", "pt_70"],
        ["Top 100", "pt_100"],
        ["Top 150", "pt_150"],
        ["Top 200", "pt_200"],
    ],
    paddingLeft: [
        ["Left 5", "pl_5"],
        ["Left 10", "pl_10"],
        ["Left 15", "pl_15"],
        ["Left 20", "pl_20"],
        ["Left 30", "pl_30"],
        ["Left 50", "pl_50"],
        ["Left 70", "pl_70"],
        ["Left 100", "pl_100"],
        ["Left 150", "pl_150"],
        ["Left 200", "pl_200"],
    ],
    paddingRight: [
        ["Right 5", "pr_5"],
        ["Right 10", "pr_10"],
        ["Right 15", "pr_15"],
        ["Right 20", "pr_20"],
        ["Right 30", "pr_30"],
        ["Right 50", "pr_50"],
        ["Right 70", "pr_70"],
        ["Right 100", "pr_100"],
        ["Right 150", "pr_150"],
        ["Right 200", "pr_200"],
    ],
}