import React from 'react';
import {deletePath} from "../../../providers/hooks";
import AddCategory from "./AddCategory";
import Image from "../../elements/edit/Image";
import Checkbox from "../../elements/edit/Checkbox";


class CategoriesItem extends React.Component   {

    state = {
        settings: false,
    }

    delete = () => {
        var r = window.confirm("Želite da obrišete kategoriju?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "categories." + this.props.item.id}).then((response) => {
            this.setState({...this.state, settings: false});
            this.props.reload();
        })
    }

    render = () => {
        let item = this.props.item;
        return  <div>

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {this.props.collapseIcon}
                    {this.props.handler}
                    {item.name}
                </div>
                <div>
                    <button
                        onClick={() => this.setState({...this.state, settings: !this.state.settings})}
                        className="btn btn-primary btn-sm">
                        <i className="fa fa-gear" />
                    </button>
                </div>
            </div>

            {this.state.settings &&
            <div className="mt-3 p-2">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <AddCategory item={item} reload={this.props.reload} />
                        </div>
                        <div className="mb-3">
                            <AddCategory parent={item.id} reload={this.props.reload} />
                        </div>
                        <button
                            onClick={this.delete}
                            className="btn btn-danger mb-3">
                            <i className="fa fa-trash" /> Obrišite
                        </button>
                        <div>
                            <label className="mr-3">
                                <Checkbox
                                    path={`categories.${item.id}.hot`}
                                    checked={item.hot} name="hot" /> HOT
                            </label>
                            <label className="mr-3">
                                <Checkbox
                                    path={`categories.${item.id}.action`}
                                    checked={item.action} name="action" /> AKCIJA
                            </label>
                            <label className="mr-3">
                                <Checkbox
                                    path={`categories.${item.id}.new`}
                                    checked={item.new} name="new" /> NOVO
                            </label>
                        </div>

                    </div>
                    <div className="col-md-6">
                        Kliknite na sliku da promenite
                        <div className="border mt-2" style={{maxWidth: 200}}>
                            <Image
                                path={item.id && `categories.${item.id}.image_id`}
                                value={item.image_name}
                                name="image_id" />
                        </div>
                    </div>
                </div>
            </div>}
        </div>;
    };
}
export default CategoriesItem;
