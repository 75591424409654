import React from 'react';
import {connect} from "react-redux";
import {closeDrawer, openDrawer} from "../../store/actions/drawersActions";

class FloatingSidebar extends React.Component   {

    state = {
        open: false,
        toTop: false,
    };

    toggleOpen = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, open: !this.state.open });
    };

    scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    openBasket = (e) => {
        e.preventDefault();
        this.props.openDrawer('basket');
    }

    openCategories = (e) => {
        e.preventDefault();
        this.props.openDrawer('categories');
    }

    render = () => {
        //const globals = this.props.globals.structure;
        const style = (this.props.selected && this.props.selected.startsWith("globals.structure.floating sidebar")) ? {left: 160, right: "auto"} : {};
        return <div className="floating_sidebar d-flex flex-column align-items-end" style={style}>


            {/*<div className="floating_help d-none d-md-flex bcg_primary">
                <a href="/" className={this.state.open ? "help_toggle help_toggle_open link" : "help_toggle link"}
                   onClick={this.toggleOpen}>
                    <div className="vertical">
                        <i className={this.state.open ? "fa fa-chevron-up mr-2" : "fa fa-chevron-down mr-2"} />
                        POMOĆ PRI KUPOVINI
                    </div>
                </a>
                <div className={this.state.open ? "slided slided_open" : "slided"}>
                    <div className="p-3 text-center weight_500">
                        <Looper
                            path="globals.structure.floating sidebar"
                            default={[{element: "text", text: "Za više informacija,pomoć i porudžbine"}]} />
                    </div>
                </div>
            </div>

            <div className="floating_item d-none d-md-block position-relative">
                <a className="link" href="/" onClick={this.openCategories}>
                    <div className="slide d-none"> Proizvodi </div>
                    <div className="icon">
                        <i className="fa fa-bars" />
                    </div>
                </a>
            </div>*/}

            {/*this.props.basket.length > 0 &&
            <div className="floating_item d-none d-md-block position-relative">
                <a className="link" href={routes.basket.route} onClick={this.openBasket}>
                    <div className="slide d-none"> Korpa </div>
                    <div className="icon">
                        <span className="text-md-center mb-1 position-relative">
                            <span className="builder_badge white">
                                {this.props.basket.length}
                            </span>
                            <img src={bagWhite} width={24} alt="User icon" />
                        </span>
                    </div>
                </a>
            </div>*/}

            {/*globals.facebook &&
            <div className="floating_item d-none d-md-block">
                <a className="link" href={globals.facebook} target="_blank" rel="noopener noreferrer">
                    <div className="slide d-none"> Facebook </div>
                    <div className="icon">
                        <i className="fa fa-facebook" />
                    </div>
                </a>
            </div>*/}

            {/*globals.youtube &&
            <div className="floating_item d-none d-md-block">
                <a className="link" href={globals.youtube} target="_blank" rel="noopener noreferrer">
                    <div className="slide d-none"> Youtube </div>
                    <div className="icon">
                        <i className="fa fa-youtube-play" />
                    </div>
                </a>
            </div>*/}

            {/*globals.twitter &&
            <div className="floating_item d-none d-md-block">
                <a className="link" href={globals.twitter} target="_blank" rel="noopener noreferrer">
                    <div className="slide d-none"> Twitter </div>
                    <div className="icon">
                        <i className="fa fa-twitter" />
                    </div>
                </a>
            </div>*/}

            {/*globals.instagram &&
            <div className="floating_item d-none d-md-block">
                <a className="link" href={globals.instagram} target="_blank" rel="noopener noreferrer">
                    <div className="slide d-none"> Instagram </div>
                    <div className="icon">
                        <i className="fa fa-instagram" />
                    </div>
                </a>
            </div>*/}

            {this.state.toTop &&
            <div className="floating_item">
                <a href="/" className="link" onClick={this.scrollToTop}>
                     <div className="icon">
                        <i className="fa fa-chevron-up" />
                     </div>
                </a>
            </div>}
        </div>
    };

    componentDidMount() {
        let t = this;
        window.addEventListener('scroll', function(e) {
            if(window.scrollY > 100) {
                if(!t.state.toTop)
                    t.setState({ ...t.state, toTop: true});
            }
            else        {
                if(t.state.toTop)
                    t.setState({ ...t.state, toTop: false});
            }
        });
    }
}
const mapStateToProps = state => ({
    selected: state.selected,
    basket: state.basket,
    globals: state.globals
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer,
}
export default connect(mapStateToProps, mapDispatchToProps)(FloatingSidebar);
