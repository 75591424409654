import React from "react";
import {Link} from "react-router-dom";
import routes from "../../../app/routes";
import {deletePath, patchProductPosition} from "../../../providers/hooks";
import {storage} from "../../../providers/helpers";
import Checkbox from "../../elements/edit/Checkbox";


class ProductsItem extends React.Component {

    state = {
        loading: true,
        products: {}
    }

    delete = () => {
        var r = window.confirm("Želite da obrišete proizvod?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "products." + this.props.item.id}).then((response) => {
            this.props.reload();
        })
    }

    toTop = () => {
        patchProductPosition({id: this.props.item.id}).then((response) => {
            this.props.reload();
        })
    }

    render = () => {
        const item = this.props.item;
        return <>
            <div style={{width: 120}}>
                <div className="bcg_image" style={{backgroundImage: `url(${storage(item.image_name)})`}} />
            </div>
            <div className="flex-grow-1 pl-3">
                <strong><Link className="prewrap" to={routes.product.route(item.id)}>{item.name}</Link></strong>
                <div> Proizvođač: {item.brand} </div>

                {/* <Price item={item} /> */}
                {item.discount_name &&
                <div className="text-primary font-weight-bold">Sniženje: {item.discount_name} ({item.discount_count}%)</div>}
            </div>
            <div style={{width: 150}}>
                <div>
                    <Checkbox
                        path={`products.${item.id}.active`}
                        checked={item.active}
                        appendTrue={<strong>Aktiviran</strong>}
                        appendFalse={<strong>Dektiviran</strong>} />
                </div>
                {!this.props.drawer.selected &&
                <>
                    <div className="mb-2">
                        <button onClick={this.toTop} className="btn btn-info btn-sm">
                            <i className="fa fa-chevron-up" /> Prebacite na vrh
                        </button>
                    </div>
                    <div className="mb-2">
                        <Link to={routes.product.route(item.id)} className="btn btn-primary btn-sm">
                            <i className="fa fa-gear" /> Podešavanja
                        </Link>
                    </div>
                </>}

                <div>
                    <button onClick={this.delete} className="btn btn-danger btn-sm">
                        <i className="fa fa-trash" /> Obrišite
                    </button>
                </div>
            </div>
        </>
    };
}

export default ProductsItem;
