import React from "react";
import {connect} from "react-redux";
import {randString} from "../../../providers/helpers";
import EmblaCarousel from "./EmblaCarousel";
import Looper from "../../Looper";
import SettingsButton from "../../fragments/SettingsButton";

class Carousel extends React.Component {

    state = {
        index: 0,
        loaded: [],
        count: parseInt(this.props.node.blockCount),
        rand: randString()
    }
    slider = null;
    scrollLeft = null;
    auto = true;
    autoSide = "right";

    renderCategory = path => {
        return <div>
            <Looper path={path}  />
        </div>
    }

    render = () => {
        const slides = Array.from(Array(this.state.count).keys()).map((i) => {
            return `${this.props.path}.slides.${i}`;
        });
        console.log(this.props.node.visibleBlocks)
        return <div className="">
            <SettingsButton path={this.props.path} />
            <EmblaCarousel slides={slides} className="category_slide" render={this.renderCategory} />
        </div>
    };




    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.node.blockCount !== prevProps.node.blockCount)        {
            this.setState({...this.state, count: parseInt(this.props.node.blockCount)})
        }
    }
}
const mapStateToProps = state => ({
    selected: state.selected
});
export default connect(mapStateToProps)(Carousel);
